import React, { useEffect, useState } from 'react';
// import { Link } from 'gatsby';
import LandingBio from '../components/landing-bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
// import Gallery from './gallery';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { SRLWrapper } from 'simple-react-lightbox';
import styled from '@emotion/styled';
import { Image } from 'theme-ui';

const Img = styled(Image)`
  cursor: pointer;
`;

const options = {
  settings: {
    // overlayColor: 'rgb(25, 136, 124)',
    autoplaySpeed: 1500,
    transitionSpeed: 900,
  },
  buttons: {
    // backgroundColor: '#1b5245',
    iconColor: 'rgba(126, 172, 139, 0.8)',
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
};

const IndexPage = () => {
  const [powerpoint, setPowerpoint] = useState([]);
  const pptGallery = [];

  useEffect(() => {
    for (let i = 1; i <= 16; i++) {
      pptGallery.push(`http://d3kvuzlw39or84.cloudfront.net/ppt/${i}.jpg`);
    }

    setPowerpoint(pptGallery);
  }, []);
  return (
    powerpoint.length !== 0 && (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <LandingBio />
        {/*<Gallery />*/}

        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350: 1,
            750: 2,
            900: 3,
            1280: 4,
            1440: 5,
            1680: 6,
          }}
        >
          <Masonry>
            {powerpoint.map((slide) => (
              <SRLWrapper options={options}>
                <Img
                  key={slide}
                  src={slide}
                  alt={`slide`}
                  style={{
                    padding: '0.6rem 0',
                    margin: '0 auto',
                    // maxHeight: '500px',
                    // width: '100%',
                    maxWidth: '300px',
                    display: 'block',
                  }}
                />
              </SRLWrapper>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </Layout>
    )
  );
};

export default IndexPage;
