import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import HyperUVImage from '../assets/uv-device.png';

const Container = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  img.uv-device {
    position: relative;
    z-index: 2;
    height: 50%;
    max-height: 600px;
  }
`;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  //height: 78vh;
  box-sizing: border-box;
  overflow: hidden;
`;

const Description = styled.p`
  padding: 0;
  margin: 3rem 0 1rem 0;
  font-size: 1.4rem;
  //position: absolute;
  //top: 30%;
  z-index: 1;
`;

const NameHeader = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 0;
`;

const LandingBio = () => (
  <StaticQuery
    query={graphql`
      query LandingSiteTitleQuery {
        site {
          siteMetadata {
            #            title
            subtitle
          }
        }
      }
    `}
    render={(data) => (
      <OuterContainer>
        <Container>
          {/*<NameHeader>{data.site.siteMetadata.title}</NameHeader>*/}
          <Description>
            Introducing a new, portable, dual-mode, large-area,
            <b style={{ color: '#7209b7' }}> ultra-violet light</b> sanitizing
            machine.
          </Description>
          <img className="uv-device" src={HyperUVImage} alt="HyperUV Device" />
        </Container>
      </OuterContainer>
    )}
  />
);

NameHeader.propTypes = {
  siteTitle: PropTypes.string,
  subtitle: PropTypes.string,
};

NameHeader.defaultProps = {
  siteTitle: ``,
  subtitle: ``,
};

export default LandingBio;
